import { Controller } from '@hotwired/stimulus';
import 'survey-core/defaultV2.fontless.css';

export default class extends Controller {
    static classes = ['ready', 'active'];
    static targets = ['modal', 'content']

    initialize () {
        this.start = this.start.bind(this);
        this.complete = this.complete.bind(this);
    }

    connect () {
        const tstamp = window.localStorage.getItem('survey');

        if (tstamp < Date.now()) {
            setTimeout(this.start, 15000)
        }
    }

    async start () {
        const Survey = (await import('survey-js-ui')).Model;
        const PlainLightPanelless = (await import('survey-core/themes/plain-light-panelless')).default

        PlainLightPanelless.cssVariables['--sjs-primary-backcolor'] = '#e85a5e';

        this.$survey = new Survey(
            {
                "locale": "fr",
                // "completedHtml": {
                //     "fr": "<h3>Merci d'avoir répondu au questionnaire !</h3>"
                // },
                // "loadingHtml": {
                //     "fr": "<h3>Le questionnaire est en cours de chargement...</h3>"
                // },
                "startSurveyText": {
                    "fr": "Commencer"
                },
                "pagePrevText": {
                    "fr": "Précédent"
                },
                "pageNextText": {
                    "fr": "Suivant"
                },
                "completeText": {
                    "fr": "Terminer"
                },
                "pages": [
                    {
                        "name": "page1",
                        "elements": [
                            {
                                "type": "expression",
                                "name": "intro",
                                "title": {
                                    "fr": "Mini-questionnaire"
                                },
                                "description": {
                                    "fr": "Cela te prendra 2 minutes d’y répondre et nous permettra d’améliorer le site en fonction de tes besoins. Merci d’avance 😉"
                                }
                            }
                        ]
                    },
                    {
                        "name": "page2",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question1",
                                "title": "As-tu trouvé les informations que tu cherchais ?",
                                "isRequired": true,
                                "choices": [
                                    {
                                        "value": "Oui",
                                        "text": "Oui"
                                    }
                                ],
                                "showOtherItem": true,
                                "otherText": "Si non, quelles informations manquaient"
                            }
                        ]
                    },
                    {
                        "name": "page3",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question2",
                                "title": "Quelle autre thématique souhaiterais-tu voir sur ce site web ?",
                                "isRequired": true,
                                "choices": [
                                    {
                                        "value": "Jeux vidéo (gaming)",
                                        "text": "Jeux vidéo (gaming)"
                                    },
                                    {
                                        "value": "Kétamine",
                                        "text": "Kétamine"
                                    },
                                    {
                                        "value": "Cocaïne",
                                        "text": "Cocaïne"
                                    }
                                ],
                                "showOtherItem": true,
                                "otherText": "Autres:"
                            }
                        ]
                    },
                    {
                        "name": "page4",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question3",
                                "title": "Trouves-tu les textes compréhensibles ?",
                                "isRequired": true,
                                "choices": [
                                    {
                                        "value": "Oui",
                                        "text": "Oui"
                                    }
                                ],
                                "showOtherItem": true,
                                "otherPlaceholder": "merci de commenter",
                                "otherText": "Non :"
                            }
                        ]
                    },
                    {
                        "name": "page5",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question4",
                                "title": "Quel âge as-tu ?",
                                "isRequired": true,
                                "choices": [
                                    {
                                        "value": "Moins de 15 ans",
                                        "text": "Moins de 15 ans"
                                    },
                                    {
                                        "value": "15 à 17 ans",
                                        "text": "15 à 17 ans"
                                    },
                                    {
                                        "value": "18 à 20 ans",
                                        "text": "18 à 20 ans"
                                    },
                                    {
                                        "value": "20 à 25 ans",
                                        "text": "20 à 25 ans"
                                    },
                                    {
                                        "value": "Plus de 25 ans",
                                        "text": "Plus de 25 ans"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "page6",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question5",
                                "title": "Quelle est ta dernière formation achevée ?",
                                "isRequired": true,
                                "choices": [
                                    {
                                        "value": "Aucune",
                                        "text": "Aucune"
                                    },
                                    {
                                        "value": "École obligatoire",
                                        "text": "École obligatoire"
                                    },
                                    {
                                        "value": "Apprentissage",
                                        "text": "Apprentissage"
                                    },
                                    {
                                        "value": "Maturité (professionnelle) ou (spécialisée) école de culture générale",
                                        "text": "Maturité (professionnelle) ou (spécialisée) école de culture générale"
                                    },
                                    {
                                        "value": "Maturité gymnasiale (gymnase, collège, lycée, etc.)",
                                        "text": "Maturité gymnasiale (gymnase, collège, lycée, etc.)"
                                    },
                                    {
                                        "value": "Haute école / haute école spécialisée / université",
                                        "text": "Haute école / haute école spécialisée / université"
                                    }
                                ],
                                "showOtherItem": true,
                                "otherText": "Autres :"
                            }
                        ]
                    },
                    {
                        "name": "page7",
                        "elements": [
                            {
                                "type": "comment",
                                "name": "question6",
                                "title": "As-tu une remarque ou une proposition d’amélioration :"
                            }
                        ]
                    }
                ],
                "showTitle": false,
                "showPageTitles": false,
                "showQuestionNumbers": "off",
                "showProgressBar": "auto",
                "progressBarInheritWidthFrom": "survey",
                "storeOthersAsComment": false,
                "goNextPageAutomatic": true,
                "allowCompleteSurveyAutomatic": false,
                "firstPageIsStarted": true,
            }
        );

        this.$survey.applyTheme(PlainLightPanelless);
        this.$survey.onComplete.add(this.complete);
        this.element.classList.add(this.readyClass);
    }

    show () {
        this.element.classList.add(this.activeClass);
        this.$survey.render(this.contentTarget);
    }

    hide () {
        this.element.remove();

        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        window.localStorage.setItem('survey', `${tomorrow.getTime()}`);
    }

    ignore () {}

    complete (survey) {
        fetch('/_survey', {
            method: 'POST',
            body: JSON.stringify(survey.data),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        survey.dispose();
        this.element.remove();

        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 30);
        window.localStorage.setItem('survey', `${tomorrow.getTime()}`);
    }
}
